import React, { FunctionComponent, useEffect, useState } from 'react';
import { IconButton } from 'assets/components/icon-button';
import { MinusCircleIcon, PlusCircleIcon } from 'assets/icons';
import { convertDataURIToBinary } from './utils';
import {
  getDocument,
  PDFDocumentProxy,
  GlobalWorkerOptions,
} from 'pdfjs-dist/legacy/build/pdf';
import pdfJSWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';

GlobalWorkerOptions.workerSrc = pdfJSWorker;

export const PDFViewer: FunctionComponent<{
  base64Data?: string;
}> = ({ base64Data = '' }) => {
  const padding = 4;
  const maxScale = 2.5;
  const minScale = 0.5;
  const step = 0.25;

  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy>();
  const [scale, setScale] = useState<number>(1);

  async function renderAllPages() {
    const canvasContainer = document.getElementById('pdf-viewer');
    canvasContainer?.replaceChildren();
    for (var i = 1; i <= (pdfDocument?.numPages ?? 1); i++) {
      await pdfDocument?.getPage(i).then(async (page) => {
        const canvas = document.createElement('canvas');
        canvas.setAttribute('id', `canvas-${i}`);
        canvas.setAttribute(
          'style',
          `background-color: black; padding: ${padding}px;`,
        );

        const viewport = page.getViewport({
          scale:
            ((window.innerWidth - 2 * padding) /
              page.getViewport({ scale: 1 }).width) *
            scale,
        });

        const canvasContext = canvas?.getContext('2d');

        var outputScale = window.devicePixelRatio;

        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);
        canvas.style.width = Math.floor(viewport.width) + 'px';
        canvas.style.height = Math.floor(viewport.height) + 'px';

        var transform =
          outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

        const renderContext = { canvasContext, viewport, transform };
        await page.render(renderContext as any);

        canvasContainer?.appendChild(canvas);
      });
    }
  }

  useEffect(() => {
    (async function () {
      const pdfResponse = await getDocument(convertDataURIToBinary(base64Data))
        .promise;

      setPdfDocument(pdfResponse);
    })();
  }, []);

  useEffect(() => {
    if (!pdfDocument) return;

    renderAllPages();
  }, [pdfDocument, scale]);

  const handleIncrement = () => {
    setScale((value) => (value < maxScale ? value + step : value));
  };

  const handleDecrement = () => {
    setScale((value) => (value > minScale ? value - step : value));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          overflowY: 'scroll',
          flexDirection: 'column',
        }}
        id="pdf-viewer"
      />
      <div
        style={{
          position: 'fixed',
          display: 'flex',
          bottom: 5,
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <IconButton
          icon={MinusCircleIcon}
          logger={{ id: 'minus' }}
          onPress={handleDecrement}
          disabled={scale <= minScale}
        />
        <IconButton
          icon={PlusCircleIcon}
          logger={{ id: 'plus' }}
          onPress={handleIncrement}
          disabled={scale >= maxScale}
        />
      </div>
    </div>
  );
};
